import { getAddressList, getDefaultAddress } from '@/api/address'
export default {
  namespaced: true,
  state () {
    return {
      addreList: [],
      addressid: '',
      defaultAddress: []
    }
  },
  mutations: {
    Clear (state) {
      state.addreList = []
    },
    SET_DEFAULT_ADDRESS_LIST (state, addressList) {
      state.defaultAddress = addressList
    },
    SET_ADDRESS_LIST (state, addressList) {
      state.addreList = addressList
    },
    SET_DEFAULT_ADDRESS (state, id) {
      state.addressid = id
    }
  },
  actions: {
    async setAddressList (context) {
      context.commit('Clear')
      const { data: { list } } = await getAddressList()
      const { data: { defaultId } } = await getDefaultAddress()
      context.commit('SET_DEFAULT_ADDRESS', defaultId)
      if (list.length === 0) {
        return
      }
      const defaultAddress = list.findIndex(item => item.address_id === defaultId)
      const Lisr = list.filter(item => item.address_id !== defaultId)
      Lisr.unshift(list[defaultAddress])
      context.commit('SET_DEFAULT_ADDRESS_LIST', Lisr)
      // console.log('List :>> ', list)
      const res = Lisr.map(item => ({
        id: item.address_id,
        name: item.name,
        tel: item.phone,
        address: item.region.province + item.region.city + item.region.region + item.detail,
        isDefault: item.address_id === defaultId
      }))
      console.log('list :>> ', Lisr)
      context.commit('SET_ADDRESS_LIST', res)
    }
  }
}
