import request from '@/utils/request'

// 获取地址列表
export const getAddressList = () => {
  return request.get('/address/list')
}

// 获取默认地址
export const getDefaultAddress = () => {
  return request.get('/address/defaultId')
}

// 编辑收货地址
export const updateAddress = (addressId, name, phone, detail, region) => {
  return request.post('/address/edit', {
    addressId: addressId,
    form: {
      name: name,
      phone: phone,
      region: region,
      detail: detail
    }
  })
}

// 删除地址
export const deleteAddress = (id) => {
  return request.post('/address/remove', {
    addressId: id
  })
}

// 添加地址
export const addAddress = (name, phone, detail, region) => {
  return request.post('/address/add', {
    form: {
      name: name,
      phone: phone,
      region: region,
      detail: detail
    }
  })
}
// // 添加地址
// export const addAddress = (name, phone, region, detail) => {
//   return request.post('/address/add', {
//     form: {
//       name: '小红',
//       phone: '13800138000',
//       region: [
//         {
//           value: 782,
//           label: '上海'
//         },
//         {
//           value: 783,
//           label: '上海市'
//         },
//         {
//           value: 785,
//           label: '徐汇区'
//         }
//       ],
//       detail: '北京路1号楼8888室'
//     }
//   })
// }
