const INFO_KEY = 'hm_shopping_info'

const SEARCH_HISTORY = 'search_history' // 搜索历史
// 获取个人信息
export const getInfo = () => {
  const defaultObj = { token: '', userId: '' }
  const result = localStorage.getItem(INFO_KEY)
  return result ? JSON.parse(result) : defaultObj
}

// 设置个人信息
export const setInfo = (info) => {
  localStorage.setItem(INFO_KEY, JSON.stringify(info))
}

// 删除个人信息
export const removeInfo = () => {
  localStorage.removeItem(INFO_KEY)
}

// 获取搜索历史
export const getSearchHistory = () => {
  const result = localStorage.getItem(SEARCH_HISTORY)
  return result ? JSON.parse(result) : []
}

// 设置搜索历史
export const setSearchHistory = (arr) => {
  localStorage.setItem(SEARCH_HISTORY, JSON.stringify(arr))
}
