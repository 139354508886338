<template>
  <div class="home">
    <!-- 导航条 -->
    <van-nav-bar title="智慧商城" fixed />
    <!-- 搜索框 -->
    <van-search
      readonly
      shape="round"
      background="#f1f1f1"
      placeholder="搜索你要找的商品"
      @click="$router.push('/search')"
    />
    <!-- 轮播图 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for=" item in bannerList" :key="item.imgUrl">
        <img :src="item.imgUrl" alt=""/>
      </van-swipe-item>
    </van-swipe>
    <!-- 滚动通知栏 -->
    <van-notice-bar
      scrollable
      background="#525252"
      color="#fff"
      left-icon="volume-o"
      text="vue2练习项目"
    />
    <!-- 导航 -->
    <van-grid class="my-grids"  clickable :column-num="5" icon-size="40">
      <van-grid-item v-for="item in navList" :key="item.imgUrl" :icon="item.imgUrl" :text="item.text" to="/category" />
    </van-grid>
    <!-- 主会场 -->
    <div class="main"><img src="@/assets/main.png" alt=""></div>
    <!-- 猜你喜欢 -->
      <div class="guess">
        <p class="guess-title">猜你喜欢</p>
        <div class="goods-list">
          <GoodsItem v-for="item in proList" :key="item.id" :item="item"></GoodsItem>
        </div>
      </div>
  </div>
</template>

<script>
import GoodsItem from '@/components/GoodsItem.vue'
import { getGoodleList } from '@/api/home'
export default {
  name: 'HomePage',
  components: {
    GoodsItem
  },
  data () {
    return {
      bannerList: [], // 轮播
      navList: [], // 导航
      proList: [], // 商品
      navtext: ''
    }
  },
  async created () {
    const { data: { pageData } } = await getGoodleList()
    // console.log('pageData :>> ', pageData)
    this.bannerList = pageData.items[1].data
    this.navList = pageData.items[3].data
    // console.log('pageData :>> ', this.navList)
    this.proList = pageData.items[6].data
    this.navtext = pageData.items[2].params.text
  }
}
</script>

<style lang="less" scoped>
// 主题 padding
.home {
  padding-top: 100px;
  padding-bottom: 50px;
}

// 导航条样式定制
.van-nav-bar {
  z-index: 999;
  // safe-area-inset-top:true;
  border-radius: 12px 12px 0 0;
  background-color: #c21401;
  ::v-deep .van-nav-bar__title {
    color: #fff;
    font-weight: bold;
  }
}

// 搜索框样式定制
.van-search {
  position: fixed;
  width: 100%;
  top: 46px;
  z-index: 999;
}

// swipe 轮播导航部分
.my-swipe .van-swipe-item {
  height: 185px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #39a9ed;
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: 185px;
}

//去除导航gard 的边框线
.my-grids ::after{
border:0px solid #fff !important;
}
// 主会场
.main img {
  display: block;
  width: 100%;
}

// 猜你喜欢
.guess .guess-title {
  height: 40px;
  line-height: 40px;
  text-align: center;
}

// 商品样式
.goods-list {
  background-color: #f6f6f6;
}
</style>
