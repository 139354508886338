import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/layout'
import Home from '@/views/layout/home'
import Category from '@/views/layout/category'
import Cart from '@/views/layout/cart'
import User from '@/views/layout/user'

import store from '@/store'

// 按需加载，路由懒加载，只有当访问到对应的路由时，才会加载对应的组件，
const Login = () => import('@/views/login')
const Search = () => import('@/views/search')
const SearchList = () => import('@/views/search/list')
const ProDetail = () => import('@/views/prodetail')
const Pay = () => import('@/views/pay')
const MyOrder = () => import('@/views/myorder')
const Address = () => import('@/views/address')
const AdderssCreate = () => import('@/views/address/create')

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      { path: '/home', component: Home },
      { path: '/category', component: Category },
      { path: '/cart', component: Cart },
      { path: '/user', component: User }
    ]
  },
  { path: '/search', component: Search },
  { path: '/searchlist', component: SearchList },
  // 动态路由传参，确认将来是哪个商品，路由参数中携带 id
  { path: '/prodetail/:id', component: ProDetail },
  { path: '/pay', component: Pay },
  { path: '/myorder', component: MyOrder },
  { path: '/address', component: Address },
  { path: '/addersscreate', component: AdderssCreate }
]

const router = new VueRouter({
  routes
})

// 所有的路由在真正被访问之前(解析渲染对应组件页面之前),都会先经过全局前置守卫,只有前置守卫放行了,才会到达对应的页面

// 全局前置导航守卫
// to:  到哪去 到哪里去的完整路由信息对象(路径,参数)
// from 从哪里 从哪里来的的完整路由信息对象(路径,参数)
// next: 是否放行 (1) next() 直接放行,到to要去的路径, (2) next(路径) 进行拦截,拦截到next里面配置的路径

// 定义一个数组，专门用户存放所有需要权限访问的页面
const authUrls = ['/pay', '/myorder', '/address']

router.beforeEach((to, from, next) => {
  // 看 to.path 是否在 authUrls 中 出现过
  if (!authUrls.includes(to.path)) {
    // 非权限页面直接放行
    next()
    return
  }
  const token = store.getters.token
  // console.log('token :>> ', token)
  if (token) {
    // 有token 放行
    next()
  } else {
    // 没有token 跳转到登录页面
    next('/login')
  }
})

export default router
